/*------------- #W-BANNER --------------*/


.w-banner {
  text-align: center;

  .w-banner-thumb {
	background-color: $primary-color;
	border-radius: 10px;
	margin-bottom: 30px;
	box-shadow: 60px 0 100px rgba(61,67,79,.05);
	color: $white-color;
	font-weight: $h-font-weight;
	position: relative;
	overflow: hidden;
	display: block;

	img {
	  display: block;
	  margin: 0 auto;
	}
  }

  .banner-content {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
  }

  .banner-size {
	position: absolute;
	bottom: 30px;
	left: 50%;
	transform: translate(-50%, 0);
  }

  .banner-sup-title {
	font-size: 100px;
	line-height: 1;
  }

  .banner-title {
	font-size: 24px;
	line-height: 1;
  }

  .banner-size {
	font-size: 14px;
  }

  .sponsor {
	text-transform: uppercase;
	color: $grey;
	font-size: 12px;
	letter-spacing: 7px;
  }
}


/*================= Responsive Mode ============*/

@media (max-width: 768px) {
  .w-banner .banner-size {
	bottom: 10px;
  }
}