/*------------- #W-HIGHLIGHTS --------------*/

.w-highlights {
  ul {
	list-style: none;
	padding-left: 0;
  }
}

.highlights-item {
  display: flex;
  position: relative;
  padding-bottom: 13px;

  &:before {
	content: '';
	display: block;
	width: 4px;
	height: auto;
	border-radius: 5px;
	background-color: $grey-light;
	position: absolute;
	left: 6px;
	bottom: 0;
	top: 26px;
  }

  & + .highlights-item {
	margin-top: 12px;
  }

  &:last-child {
	&:before {
	  display: none;
	}
  }

  .circle {
	min-width: 16px;
	min-height: 16px;
	max-width: 16px;
	max-height: 16px;
	margin-right: 30px;
	border-radius: 100%;
	border: 3px solid $secondary-color;
  }

  .content-description {
	overflow: hidden;

	.title {
	  line-height: 1;
	  margin-bottom: 5px;
	  font-weight: $h-font-weight;
	  color: $secondary-color;
	}
  }
}

.w-highlights--without-decor {
  .highlights-item:before {
	display: none;
  }
}