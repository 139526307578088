/*------------- #W-LATEST-NEWS --------------*/


.w-latest-news {
  .latest-news-list {
	list-style: none;
	padding-left: 0;
	margin: 0;
  }

  .category {
	font-weight: $h-font-weight;
	color: $primary-color;
	font-size: 14px;
  }

  .post__title {
	font-size: 16px;
	margin: 8px 0;
	line-height: 1.65em;
  }

  .divider {
	margin: 10px auto;
  }
}