/*------------- #W-POPULAR-POSTS --------------*/


.w-popular-posts {
  text-align: center;
  padding-bottom: 40px;

  .post {
	border-radius: 0;
  }

  .post-thumb {
	border-radius: 0;
	margin: 0 auto 40px;
  }

  .post__content {
	padding: 0;
  }

  .post-additional-info {
	justify-content: center;
  }

  .swiper-pagination {
	bottom: 30px;
  }
}

.sidebar .w-popular-posts {
  padding-bottom: 40px;
}