/*------------- #WIDGETS --------------*/

@import "theme-styles/variables";

@import "widgets/w-category-links";
@import "widgets/w-tags";
@import "widgets/w-info";
@import "widgets/w-contacts";
@import "widgets/w-highlights";
@import "widgets/w-search";
@import "widgets/w-about";
@import "widgets/w-category";
@import "widgets/w-subscribe";
@import "widgets/w-latest-news";
@import "widgets/w-popular-products";
@import "widgets/w-recent-comments";
@import "widgets/w-banner";
@import "widgets/w-socials-counter";
@import "widgets/w-calendar";
@import "widgets/w-popular-posts";
@import "widgets/w-shared-posts";
@import "widgets/w-popular-tags";
@import "widgets/w-socials-subscriber";