/*------------- #W-CONTACTS --------------*/


.w-contacts {
  .socials {
	margin-bottom: 40px;
  }

  .send-us {
	color: $heading-color;
  }
}